import { newsService } from "../_services";
import { router } from "../_helpers";

const state = { onRequest: false, listnews: [], news: {} };

const actions = {
	get({ dispatch, commit }, id) {
		commit("request");

		newsService.get(id).then(
			(resp) => {
				commit("success_data", resp);
			},
			(error) => {
				commit("error");
				dispatch("alert/error", error, { root: true });
			}
		);
	},
	add({ dispatch, commit }, data) {
		commit("request");

		newsService.add(data).then(
			async () => {
				commit("success");
				await router.push("/news");
				dispatch("alert/success", "News Added Successfully", { root: true });
			},
			async (error) => {
				commit("error");
				await router.push("/news");
				dispatch("alert/error", error, { root: true });
			}
		);
	},
	edit({ dispatch, commit }, data) {
		commit("request");

		newsService.edit(data).then(
			async () => {
				commit("success");
				await router.push("/news");
				dispatch("alert/success", "News Updated Successfully", { root: true });
			},
			async (error) => {
				commit("error");
				await router.push("/news");
				dispatch("alert/error", error, { root: true });
			}
		);
	},
	get_all({ dispatch, commit }) {
		commit("request");
		console.log("Masuk Get ALL !!!");
		newsService.get_all().then(
			(resp) => {
				commit("success_datas", resp);
			},
			(error) => {
				commit("error");
				dispatch("alert/error", error, { root: true });
			}
		);
	},
	destroy({ dispatch, commit }, id) {
		commit("request");

		newsService.destroy(id).then(
			async () => {
				commit("success");
				router.go("/news");
				dispatch("alert/success", "News Deleted Successfully", { root: true });
			},
			async (error) => {
				commit("error");
				router.go("/news");
				dispatch("alert/error", error, { root: true });
			}
		);
	},
};
const getters = {
	onRequest: (state) => {
		return state.onRequest;
	},
	news: (state) => {
		return state.listnews;
	},
	anews: (state) => {
		return state.news;
	},
};

const mutations = {
	request(state) {
		state.onRequest = true;
		state.news = {};
		state.listnews = [];
	},
	success(state) {
		state.onRequest = false;
	},
	success_data(state, data) {
		state.onRequest = false;
		state.news = data;
	},
	success_datas(state, data) {
		state.onRequest = false;
		state.listnews = data;
	},
	error(state) {
		state.onRequest = false;
	},
};

export const news = {
	namespaced: true,
	state,
	actions,
	getters,
	mutations,
};
